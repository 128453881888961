<template>
  <div class="header" :class="`${theme}`">
    <div class="header_container">
      <div class="title">{{ title }}</div>
      <div v-if="isGoBack == true" class="back" :class="{ hideGoBack: hideGoBack }" @click="goBack(step)">
        <img class="icon" :src="imageUrl" />
      </div>
      <div v-else class="back" :class="{ hideGoBack: hideGoBack }" @click="goAnyWay(path)">
        <img class="icon" :src="imageUrl" />
      </div>
      <!-- ========== slot ==========  -->
      <div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    step: {
      type: [Number, String],
      default: -1,
    },
    title: {
      type: String,
      default: "标题文字",
    },
    theme: {
      type: String,
      //primary && light=>预设白底
      default: "primary",
    },
    hideGoBack: {
      type: Boolean,
      default: false,
    },
    isGoBack: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      default: '/',
    }
  },
  computed: {
    imageUrl() {
      let primary = require("../assets/images/login/ic_redeem_return.webp");
      let light = require("../assets/images/login/ic_common_back.webp");
      return this.theme === "primary" ? primary : light;
    },
  },
  methods: {
    goBack(step) {
      typeof step === "number"
        ? this.$router.go(step)
        : this.$router.push({ path: step });
    },
    goAnyWay(path) {
      this.$router.push(path)
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
}

.header_container {
  width: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .back {
    width: 5rem;
    padding: 0.75rem 0.75rem 0.75rem 0rem;
    position: absolute;
    left: 0;
    font-size: 0;
    height: 100%;

    img {
      width: 1.5rem;
      height: auto;
    }
  }
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.primary {
  .header_container {
    background-color: var(--orange);
  }

  .title {
    color: white;
  }
}

.light {
  .header_container {
    background-color: white;
    border-bottom: 1px solid var(--light-gray);
  }

  .title {
    color: var(--black);
  }
}

.hideGoBack {
  display: none;
}
</style>
