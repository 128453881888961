<template>
  <Container theme="dark">
    <Header :isGoBack="false" :path="pathBack" :title="`收款通道`" theme="light" />
    <PaymentChannelItem v-for="item in lists" :key="item.title" :title="item.title" :imageUrl="item.icon" :path="path"
      :params="item.params" @goToPath="goToPath" />
  </Container>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/Header.vue";

import alipay from "@/assets/images/public/ic_my_money_aisle_alipay.webp";
import WeChat from "@/assets/images/public/ic_my_money_aisle_wechat.webp";
import BankCard from "@/assets/images/public/ic_my_money_aisle_bank.webp";
import PaymentChannelItem from "../components/PaymentChannelItem";
import Container from "../components/Container.vue";
export default {
  components: {
    PaymentChannelItem,
    Container,
    Header
  },
  data() {
    return {
      status: 1, //状态 0关闭 1开启,
      isLoading: false,
      path: "/my/paymentMethod/payment",
      pathBack: '/',
      lists: [
        {
          icon: alipay,
          title: "支付宝",
          params: "alipay",
        },
        {
          icon: WeChat,
          title: "微信",
          params: "WeChat",
        },
        {
          icon: BankCard,
          title: "银行卡",
          params: "BankCard",
        },
      ],
    };
  },
  methods: {
    ...mapActions("account", ["getAccounts"]),
    goToPath(path, params) {
      this.$router.push({
        path: path,
        query: { method: params },
      });
    },
  },
  mounted() {
    this.getAccounts({
      status: this.status,
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
