<template>
  <div class="card" @click="goToPath">
    <div class="d_flex justify_content_between">
      <div class="d_flex">
        <div class="icon" style="margin-right: 0.5rem">
          <img :src="imageUrl" v-if="imageUrl" />
        </div>
        <div class="title">{{ title }}</div>
      </div>
      <div class="icon">
        <img src="../../../assets/images/public/ic_my_money_aisle_back.webp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    params: {
      type: String,
      default: "",
    },
    openBlankPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPath() {
      this.$emit('goToPath',this.path,this.params)
    },
  },
};
</script>

<style lang="scss" scoped>
.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.card {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 0rem;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 0.75rem;
}

.card:nth-child(1){
  margin-top: .75rem;
}

.icon {
  display: block;
  width: 2rem;
  transform: translateY(0.1rem);
  margin: 0 0.85rem;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
</style>